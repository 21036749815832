"use client";

import { useState } from "react";
import Image from "next/image";
import { ArrowRight } from "lucide-react";
import { z } from "zod";
import { Button } from "@repo/ui/button";
import { Input } from "@repo/ui/input";
import { useToast } from "@repo/ui/use-toast";
import { saveWaitlistSignup } from "@/app/actions/waitlist";

export function Hero() {
  const [isOnWaitlist, setIsOnWaitlist] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (formData: FormData) => {
    const email = formData.get("email")?.toString();
    const isValidEmail = z.string().email().safeParse(email).success;
    if (!isValidEmail) {
      return;
    }
    const result = await saveWaitlistSignup(formData);
    console.log({ result });
    if (result.success) {
      setIsOnWaitlist(true);
      toast({
        title: "Success!",
        description: "You've been added to the waitlist.",
        variant: "default",
      });
    } else {
      setIsOnWaitlist(false);
      toast({
        title: "Error",
        description: "Failed to join the waitlist. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <section
      id="hero"
      className="container flex flex-col items-center gap-8 pb-28 pt-20 sm:gap-10"
    >
      <div className="bg-secondary hover:bg-secondary/60 flex cursor-pointer items-center gap-1 rounded-full border px-3 py-0.5">
        <span className="text-secondary-foreground text-sm">
          Announcing faqtory.io
        </span>
        <ArrowRight size={16} />
      </div>
      <h1 className="font-heading max-w-3xl text-center text-4xl font-semibold sm:text-5xl sm:leading-tight">
        AI-powered FAQs
      </h1>
      <p className="text-muted-foreground max-w-xl text-center text-lg sm:text-xl">
        Stop losing time, answering the same questions over and over.
      </p>
      {/* <p className="text-muted-foreground max-w-xl text-center text-lg sm:text-xl">
        Faqtory&apos;s AI-powered FAQs will streamline your support process by
        answering questions using your knowledge base.
      </p> */}
      <form
        action={handleSubmit}
        className="flex w-full max-w-md flex-col gap-2 sm:flex-row sm:items-center"
      >
        <Input
          type="email"
          name="email"
          placeholder="Enter your email"
          className="border-border bg-card h-12 px-6 text-lg focus-visible:ring-0 focus-visible:ring-offset-0 sm:h-14 sm:flex-1"
        />
        <Button
          size="lg"
          // asChild
          className="h-12 cursor-pointer text-base sm:h-14"
          type="submit"
          variant="default"
        >
          {isOnWaitlist ? "👍 Added to Waitlist" : "Join Waitlist"}
        </Button>
      </form>
      <div className="relative mt-24 sm:mt-8">
        <Image
          alt="SaaS Dashboard"
          src="/images/faqtory-illustration.svg"
          width={1600}
          height={698}
          priority
          // className="border-border rounded-xl border shadow-lg"
        />
        <div className="bg-primary/20 absolute inset-0 -z-10 [filter:blur(180px)]" />
      </div>
    </section>
  );
}
